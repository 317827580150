import { ElementRef, Injectable } from '@angular/core';
import { BootstrapService } from '@core/bootstrap.service';
import { BrowserService } from '@core/browser.service';
import { forkJoin, fromEvent, of, race, Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';


import { VideoContainerComponent } from './video-container/video-container.component';

/** Preloads a set of assets and indicates when the load is complete */
@Injectable()
export class Preloader {
  loadEvents: Array<Observable<{}>> = [];
  private _totalLoaded = 0;
  private images: HTMLImageElement[] = [];

  constructor(private browser: BrowserService, private bootstrap: BootstrapService) { }

  get totalLoaded() { return this._totalLoaded; }

  reset(): Preloader {
    this.loadEvents = [];
    this.images = [];
    this._totalLoaded = 0;
    return this;
  }

  addImagesFromPath(rootPath: string, assets: Array<string>): Preloader {
    assets.forEach((a) => {
      this.addImage(`${rootPath}${a}`);
    });

    return this;
  }

  addImages(assets: Array<string>): Preloader {
    assets.forEach((a) => {
      this.addImage(a);
    });

    return this;
  }

  addImage(path: string): Preloader {
    const i = document.createElement('img');

    const obs = fromEvent(i, 'load')
      .pipe(
        map((r) => {
          this._totalLoaded++;
          return r;
        }),
        take(1)
      );
    const errorObs = fromEvent(i, 'error')
      .pipe(
        map((r) => {
          console.error(`image preload error: missing image ${path}`);
          this._totalLoaded++;
          return r;
        }),
        take(1)
      );
    this.loadEvents.push(race([obs, errorObs]));
    i.src = path;
    this.images.push(i);

    return this;
  }

  addVideoElement(e: ElementRef): Preloader {
    let v = e.nativeElement as HTMLVideoElement;

    // if safari use a dummy to preload
    if (!v.src && !!v.currentSrc && this.browser.isSafari) {
      if (this.bootstrap.isLocalUrl) {
        console.log('safari video spotted');
      }
      const newV = document.createElement('video');
      newV.src = v.currentSrc;
      v = newV;
    }

    // It is best to use currentSrc because media source can be derived from either the
    // HTMLMediaElement or the HTMLSourceElement, but src uses only HTMLMediaElement.
    if (v.currentSrc) {
      const obs = fromEvent(v, 'loadeddata')
        .pipe(
          catchError(() => {
            console.error('loadeddata: missing video');
            return of({});
          }),
          map((r) => {
            if (this.bootstrap.isLocalUrl) {
              console.log(`loaded video: ${v.currentSrc}`);
            }

            this._totalLoaded++;
            return r;
          }),
          take(1)
        );

      const errorObs: Observable<any> = fromEvent(v, 'error')
        .pipe(
          catchError(() => {
            console.error('video error: missing video');
            return of({});
          }),
          map((r: ErrorEvent) => {
            console.error('missing video');
            console.error(r.error);
          }),
          take(1)
        );

      this.loadEvents.push(race([obs, errorObs]));
      v.load();
      if (this.bootstrap.isLocalUrl) {
        console.log(`started preload video: ${v.currentSrc}`);
      }
    } else if (!this.browser.isIEorEdge) {
      v.preload = 'auto';
    } else if (this.bootstrap.isLocalUrl) {
      console.log(v.currentSrc);
      console.log(v.src);
      console.log(v.childNodes[2]);
    }

    return this;
  }

  addVideoContainer(c: VideoContainerComponent): Preloader {
    return this.addVideoElement(c.videoRef);
  }

  load(): Observable<boolean> {
    // Note that this will not emit until _all_ observables have emitted at least once.
    return forkJoin(this.loadEvents)
      .pipe(
        map(() => {
          if (this.bootstrap.isLocalUrl) {
            console.log(`preload complete`);
          }
          return true;
        })
      );
  }
}

